import React, { useEffect, useContext, useState } from 'react';
import '../css/Splash.css'; // Adjust the path based on your file structure
import { AlexaContext } from '../utils/AlexaService';
import generalFunctions from '../utils/generalFunctions';

const Splash = ({ buttonsEnabled }) => {
  const { gameData, setMessage } = useContext(AlexaContext);
  const currentLocale = gameData.locale; // Default to "en-US" if no locale is provided
  const [assets, setAssets] = useState({ daily: null, category: null, leaderboard: null, logo: null });

  useEffect(() => {
    const loadAssets = async () => {
      let daily, category, leaderboard, logo;

      try {
        switch (currentLocale) {
          case 'es-ES':
            logo = (await import('../img/spanish/btb-just-logo.png')).default;
            daily = (await import('../img/spanish/daily-button.png')).default;
            category = (await import('../img/spanish/category-button.png')).default;
            leaderboard = (await import('../img/spanish/leaderboard-button.png')).default;
            break;
          case 'fr-FR':
            logo = (await import('../img/french/btb-just-logo.png')).default;
            daily = (await import('../img/french/daily-button.png')).default;
            category = (await import('../img/french/category-button.png')).default;
            leaderboard = (await import('../img/french/leaderboard-button.png')).default;
            break;
          case 'de-DE':
            logo = (await import('../img/german/btb-just-logo.png')).default;
            daily = (await import('../img/german/daily-button.png')).default;
            category = (await import('../img/german/category-button.png')).default;
            break;
          case 'en-US':
            // Fallback to English assets
            logo = (await import('../img/english/btb-just-logo.png')).default;
            daily = (await import('../img/english/daily-button.png')).default;
            category = (await import('../img/english/category-button.png')).default;
            leaderboard = (await import('../img/english/leaderboard-button.png')).default;
            break;
          default: break;
        }
      } catch (error) {
        console.error("Error loading assets: ", error);
      }

      if (daily) {
        setAssets({ daily, category, leaderboard, logo });
      }

    };

    loadAssets();
  }, [currentLocale]);

  function selectDaily(event) {
    generalFunctions.animateButton(event.currentTarget.id);
    window.sendMessage({
      "START_BTB_DAILY": true
    });
  }

  function selectCategory(event) {
    generalFunctions.animateButton(event.currentTarget.id);
    window.sendMessage({
      "ASK_BTB_CATEGORY": true
    });
  }

  function selectLeaderboard(event) {
    generalFunctions.animateButton(event.currentTarget.id);
    setMessage({
      message: "SHOW_LEADERBOARD"
    });
  }

  return (
    <div className="splash">
      {assets.logo && (
        <img src={assets.logo} alt="Logo" className="logo" />
      )}
      <div className="buttons-container">
        {assets.daily && (
          <button
            id="daily-id"
            className={`button daily-button${buttonsEnabled ? '' : ' disabled'}`}
            aria-label="Daily"
            onClick={selectDaily}
            style={{ backgroundImage: `url(${assets.daily})` }}
          />
        )}
        {assets.category && (
          <button
            id="category-id"
            className={`button category-button${buttonsEnabled ? '' : ' disabled'}`}
            aria-label="Category"
            onClick={selectCategory}
            style={{ backgroundImage: `url(${assets.category})` }}
          />
        )}
        {assets.leaderboard && (
          <button
            id="leaderboard-button-id"
            className={`button leaderboard-button-splash${buttonsEnabled ? '' : ' disabled'}`}
            aria-label="Leaderboard"
            onClick={selectLeaderboard}
            style={{ backgroundImage: `url(${assets.leaderboard})` }}
          />
        )}
      </div>
    </div>
  );
};

export default Splash;
