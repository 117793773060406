import React, { useContext, useEffect, useState } from 'react';
import '../css/QuestionView.css';
import background from '../img/btb-background-v2.jpg'; // Static background
import greenStep from '../img/green-step.png'; // Static step indicator
import yellowStep from '../img/yellow-step.png'; // Static step indicator
import questionBox from '../img/question-box.png'; // Static question box
import questionBar from '../img/question-bar.png'; // Static question bar

import { AlexaContext } from '../utils/AlexaService';
import generalFunctions from '../utils/generalFunctions';

const QuestionView = ({ buttonsEnabled }) => {
    const { gameData } = useContext(AlexaContext);
    const steps = [false, false, false, false, false];

    const currentLocale = gameData.locale || "en-US"; // Default to "en-US" if locale is not provided
    const [assets, setAssets] = useState({ trueButton: null, falseButton: null });

    useEffect(() => {
        const loadAssets = async () => {
            let trueButton, falseButton;

            try {
                switch (currentLocale) {
                    case 'es-ES':
                        trueButton = (await import('../img/spanish/true-button.png')).default;
                        falseButton = (await import('../img/spanish/false-button-v3.png')).default;
                        break;
                    case 'fr-FR':
                        trueButton = (await import('../img/french/true-button.png')).default;
                        falseButton = (await import('../img/french/false-button-v3.png')).default;
                        break;
                    case 'de-DE':
                        trueButton = (await import('../img/german/true-button.png')).default;
                        falseButton = (await import('../img/german/false-button-v3.png')).default;
                        break;
                    default:
                        // Fallback to English assets
                        trueButton = (await import('../img/english/true-button.png')).default;
                        falseButton = (await import('../img/english/false-button-v3.png')).default;
                        break;
                }
            } catch (error) {
                console.error("Error loading assets: ", error);
            }

            setAssets({ trueButton, falseButton });
        };

        loadAssets();
    }, [currentLocale]);

    if (gameData.btbQuestionCount) {
        for (let x = 0; x < gameData.btbQuestionCount - 1; x++) {
            steps[x] = true;
        }
    }

    function pressTrue(event) {
        generalFunctions.animateButton(event.currentTarget.id);
        window.sendMessage({ BTB_TRUE: true });
    }

    function pressFalse(event) {
        generalFunctions.animateButton(event.currentTarget.id);
        window.sendMessage({ BTB_FALSE: true });
    }

    return (
        <div className="question-view" style={{ backgroundImage: `url(${background})` }}>
            <div className="progress-bar" style={{ backgroundImage: `url(${questionBar})` }}>
                {steps.map((step, index) => (
                    <img
                        key={index}
                        src={step ? greenStep : yellowStep}
                        alt="Step"
                        className="step-image"
                    />
                ))}
            </div>
            <div className="question-box">
                <img src={questionBox} alt="Question Box" className="question-box-image" />
                <p className="question-text">{gameData.question || ""}</p>
            </div>
            <div className="question-buttons-container">
                {assets.trueButton && (
                    <img
                        src={assets.trueButton}
                        id="true-button"
                        alt="True"
                        className={`question-button${buttonsEnabled ? '' : ' disabled'}`}
                        onClick={buttonsEnabled ? pressTrue : null} // Disable onClick event if buttons are disabled
                    />
                )}
                {assets.falseButton && (
                    <img
                        src={assets.falseButton}
                        id="false-button"
                        alt="False"
                        className={`question-button${buttonsEnabled ? '' : ' disabled'}`}
                        onClick={buttonsEnabled ? pressFalse : null} // Disable onClick event if buttons are disabled
                    />
                )}
            </div>
        </div>
    );
};

export default QuestionView;
