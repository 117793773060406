import React, { useContext, useEffect, useState } from 'react';
import '../css/Leaderboard.css'; // Ensure you have this CSS file in your project

import generalFunctions from '../utils/generalFunctions';
import { AlexaContext } from '../utils/AlexaService';

import Buzzer from '../img/buzzer.png'; // Static import for the buzzer icon

const speechMap = {
    "en-US": {
        "Rank": "Rank",
        "Trophies": "Trophies",
        "Name": "Name"
    },
    "de-DE": {
        "Rank": "Rang",
        "Trophies": "Trophäen",
        "Name": "Name"
    },
    "fr-FR": {
        "Rank": "Rang",
        "Trophies": "Trophées",
        "Name": "Nom"
    },
    "es-ES": {
        "Rank": "Rango",
        "Trophies": "Trofeos",
        "Name": "Nombre"
    }
};


const Leaderboard = () => {
    const { setMessage, gameData } = useContext(AlexaContext);
    const currentLocale = gameData.locale || "en-US"; // Default to "en-US" if locale is not provided
    const [assets, setAssets] = useState({ playAgain: null, switchGame: null });

    const [currentPlayer, setCurrentPlayer] = useState({ name: 'Mini Games Player', score: 0 });
    const [topPlayers, setTopPlayers] = useState([
        { name: 'Rank 1', score: 999 },
        { name: 'Rank 2', score: 999 },
        { name: 'Rank 3', score: 999 },
        { name: 'Rank 4', score: 999 },
        { name: 'Rank 5', score: 999 },
        { name: 'Rank 6', score: 999 }
    ]);

    // Dynamically import images based on locale
    useEffect(() => {
        const loadAssets = async () => {
            let playAgain, switchGame;

            try {
                switch (currentLocale) {
                    case 'es-ES':
                        playAgain = (await import('../img/spanish/play-again.png')).default;
                        switchGame = (await import('../img/spanish/switch-game.png')).default;
                        break;
                    case 'fr-FR':
                        playAgain = (await import('../img/french/play-again.png')).default;
                        switchGame = (await import('../img/french/switch-game.png')).default;
                        break;
                    case 'de-DE':
                        playAgain = (await import('../img/german/play-again.png')).default;
                        switchGame = (await import('../img/german/switch-game.png')).default;
                        break;
                    default:
                        // Default to English assets
                        playAgain = (await import('../img/english/play-again.png')).default;
                        switchGame = (await import('../img/english/switch-game.png')).default;
                        break;
                }
            } catch (error) {
                console.error("Error loading assets: ", error);
            }

            setAssets({ playAgain, switchGame });
        };

        loadAssets();
    }, [currentLocale]);

    useEffect(() => {
        if (gameData.currentPlayer) {
            setCurrentPlayer(gameData.currentPlayer);
        }

        if (gameData.topPlayers) {
            setTopPlayers(gameData.topPlayers);
        }
    }, [gameData]);

    function playAgain2(event) {
        generalFunctions.animateButton(event.currentTarget.id);
        setMessage({
            message: "SHOW_SPLASH"
        });

        window.sendMessage({
            "BTB_INTRO": true
        });
    }

    function switchGame2(event) {
        generalFunctions.animateButton(event.currentTarget.id);
        window.sendMessage({
            switchGame: true
        });
    }

    return (
        <div className="leaderboard-container">
            <div className="current-player-container">
                <div className="player-info">
                    <span className="player-rank">{speechMap[gameData.locale].Rank}: #{currentPlayer.rank}</span>
                    <img src={Buzzer} alt="Buzzer" className="player-buzzer" />
                    <span className="player-name">{currentPlayer.name}</span>
                    <div className="score-container">
                        <span className="player-score">{currentPlayer.score}</span>
                    </div>
                </div>
            </div>

            <div className="leaderboard-list-container">
                <ul className="leaderboard-list">
                    <li className="leaderboard-header">
                        <span className="header-rank">{speechMap[gameData.locale].Rank}</span>
                        <span className="header-name">{speechMap[gameData.locale].Name}</span>
                        <span className="header-score">{speechMap[gameData.locale].Trophies}</span>
                    </li>
                    {topPlayers.sort((a, b) => b.score - a.score).map((player, index) => (
                        <li key={index} className="leaderboard-item">
                            <span className="rank">#{index + 1}</span>
                            <span className="name">{player.name}</span>
                            <span className="score">{player.score}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="leaderboard-buttons-container">
                {assets.playAgain && (
                    <button className="leaderboard-button">
                        <img src={assets.playAgain} alt="Play Again" onClick={playAgain2} />
                    </button>
                )}
                {assets.switchGame && (
                    <button className="leaderboard-button">
                        <img src={assets.switchGame} alt="Switch Game" onClick={switchGame2} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Leaderboard;
