import React, { useEffect, useContext, useState } from 'react';
import '../css/FactView.css'; // Import your CSS file
import correctBacker from '../img/correct-fact-backer.jpg'; // Always imported as the background
import incorrectBacker from '../img/incorrect-fact-backer.jpg'; // Always imported as the background

import { AlexaContext } from '../utils/AlexaService';

const FactView = () => {
  const { message, gameData } = useContext(AlexaContext);
  const correct = gameData?.correct;
  const currentLocale = gameData.locale || "en-US"; // Default to "en-US" if locale is not provided
  const [assets, setAssets] = useState({ correctBox: null, incorrectBox: null });
  const backgroundImage = correct ? correctBacker : incorrectBacker;

  useEffect(() => {
    const loadAssets = async () => {
      let correctBox, incorrectBox;

      try {
        // Dynamically import assets based on the locale
        switch (currentLocale) {
          case 'es-ES':
            correctBox = (await import('../img/spanish/correct-box.png')).default;
            incorrectBox = (await import('../img/spanish/incorrect-box.png')).default;
            break;
          case 'fr-FR':
            correctBox = (await import('../img/french/correct-box.png')).default;
            incorrectBox = (await import('../img/french/incorrect-box.png')).default;
            break;
          case 'de-DE':
            correctBox = (await import('../img/german/correct-box.png')).default;
            incorrectBox = (await import('../img/german/incorrect-box.png')).default;
            break;
          default:
            // Fallback to English assets as default
            correctBox = (await import('../img/english/correct-box.png')).default;
            incorrectBox = (await import('../img/english/incorrect-box.png')).default;
            break;
        }
      } catch (error) {
        console.error("Error loading assets: ", error);
      }

      // Set the dynamically imported assets
      setAssets({ correctBox, incorrectBox });
    };

    loadAssets();
  }, [currentLocale]);

  return (
    <div className="fact-view" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Display the correct or incorrect fact box image */}
      {assets.correctBox && assets.incorrectBox && (
        <img
          src={correct ? assets.correctBox : assets.incorrectBox}
          className="fact-image"
          alt="Box"
        />
      )}
      <div className={correct ? 'fact-box correct' : 'fact-box incorrect'}>
        <p className="fact-text">{gameData?.fact}</p>
      </div>
    </div>
  );
};

export default FactView;
