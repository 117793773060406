function animateButton(buttonId) {
    const button = document.getElementById(buttonId);
    if (!button) return;
  
    button.classList.add('pressed');
    setTimeout(() => {
      button.classList.remove('pressed');
    }, 200); // Adjust the timing as necessary
  }
  
export default {
    animateButton,
}