import React, { useContext, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import '../css/CenteredBox.css';
import { AlexaContext } from '../utils/AlexaService';
import Trophy from '../img/trophy.png';

// Wrap the component with forwardRef
const CenterTrophy = forwardRef((props, ref) => {
  const { gameData } = useContext(AlexaContext);
  const quizData = gameData.quizData || {};
  const [animate, setAnimate] = useState(false);

  const animateTrophy = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false); // Reset animation state after the animation duration
    }, 4000); // Match this duration to your CSS animation duration
  };

  // Use useImperativeHandle to expose specific functions to the parent component
  useImperativeHandle(ref, () => ({
    animateTrophy
  }));

  return (
    <div className="centered-container">
      <img src={Trophy} alt={quizData.categoryName || "Daily"} className={`centered-image ${animate ? 'animate' : 'trophy'}`} />
    </div>
  );
});

export default CenterTrophy;
