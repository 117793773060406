const playAudio = (audio) => {
  let cleanupCalled = false;

  const play = () => {

    if (audio) {
      audio.play().catch((error) => {
        console.error('Playback error:', error);
      });
  
      audio.onended = () => {
        if (!cleanupCalled) {
          cleanupCalled = true;
          cleanupAudio();
        }
      };
    }
  };

  const cleanupAudio = () => {
    if (audio) {
      audio.src = '';
      audio.onended = null;
      audio = null;
    }
  };

  // Return an object with both the play and cleanup functions
  return {
    play,
    cleanup: cleanupAudio,
  };
};

const audioContext = new AudioContext();


function playWithIncreasedVolume(audioUrl, gainValue, onEndedCallback = null, triggerEarlyCallback = false) {
  fetch(audioUrl)
    .then(response => response.arrayBuffer())
    .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
    .then(audioBuffer => {
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      const gainNode = audioContext.createGain();
      gainNode.gain.value = gainValue;

      source.connect(gainNode);
      gainNode.connect(audioContext.destination);

      // If triggerEarlyCallback is true, schedule the callback to be called 0.5s before the audio ends
      let callbackTimeoutId;
      if (triggerEarlyCallback && onEndedCallback) {
        const duration = audioBuffer.duration;
        const earlyCallbackTime = 0.675; // Time in seconds before the audio ends
        const triggerTime = (duration - earlyCallbackTime) * 1000; // Convert to milliseconds

        callbackTimeoutId = setTimeout(() => {
          onEndedCallback();
        }, triggerTime);
      }

      source.onended = () => {
        if (callbackTimeoutId) {
          clearTimeout(callbackTimeoutId); // Prevent the callback from being called again if it has already been triggered
        }
        source.disconnect();
        gainNode.disconnect();
        if (!triggerEarlyCallback && onEndedCallback) {
          onEndedCallback(); // Call onEndedCallback if triggerEarlyCallback is false
        }
      };

      source.start();
    })
    .catch(err => {
      if (onEndedCallback) onEndedCallback(err);
    });
}

function playAudioSequence(audioFiles, gainValue = 2) {
  let currentFileIndex = 0;


  function playFile(index) {
      if (index < audioFiles.length) {
          const currentItem = audioFiles[index];

          // Check if the current item is a number (milliseconds for delay)
          if (typeof currentItem === 'number') {
              setTimeout(() => {
                  currentFileIndex++;
                  playFile(currentFileIndex);
              }, currentItem);
          } else {
              // Assuming the item is an audio file URL
              // if (index === 0) disableAllButtons();
              playWithIncreasedVolume(currentItem, gainValue, () => {
                  currentFileIndex++;
                  playFile(currentFileIndex);
              });
          }
      }
  }

  playFile(currentFileIndex);
}

export default {
  playAudio,
  playWithIncreasedVolume,
  playAudioSequence
}