import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import lottie from 'lottie-web';
import WinCoin from '../audio/coin-sounds/win-1.mp3';

const CoinAnimation = forwardRef((props, ref) => {
    const animationRef = useRef();
    const animationInstance = useRef(null); // Store the animation instance
  
    // Expose a method to trigger the animation
    useImperativeHandle(ref, () => ({
      playAnimation: (coinsEarned) => {
        if (!animationInstance.current) {
          const animationData = require('../lottie/coin_animation.json');
          animationInstance.current = lottie.loadAnimation({
            container: animationRef.current, // Reference to the container for the animation
            renderer: 'svg',
            loop: false,
            autoplay: false, // Changed to false so we can control playback
            animationData: animationData,
            preserveAspectRatio: 'xMidYMid meet',
          });
  
          animationInstance.current.addEventListener('complete', () => {
            // Reset animation to its initial state
            animationInstance.current.goToAndStop(0, true);
  
            let gameOverWrapper = document.getElementsByClassName("win-coins")[0];
            if (gameOverWrapper) {
              gameOverWrapper.style.display = "none";
            }
          });
        }

        const coinAudio = document.getElementById("generic-audio");

        if (coinAudio) {
            coinAudio.src = WinCoin;
            coinAudio.play().catch((err) => console.log(JSON.stringify(err)));
        }
  
        // Update coin count
        const coinCountElement = document.getElementById('coin-count');
        if (coinCountElement) {
          coinCountElement.textContent = `${Number(coinCountElement.textContent) + Number(coinsEarned)}`;
        }
  
        // Make sure the container is visible
        let gameOverWrapper = document.getElementsByClassName("win-coins")[0];
        if (gameOverWrapper) {
          gameOverWrapper.style.display = "block";
        }
  
        // Play the animation from the beginning
        animationInstance.current.goToAndPlay(0, true);
      },
    }));
  
    return (
      <div className='win-coins' style={{ display: 'none' }}> {/* Initially hidden */}
        <div ref={animationRef} />
      </div>
    );
  });
  
export default CoinAnimation;
