import React, { useEffect } from 'react';
import '../css/QuizList.css'; // Import your CSS file

import Buzzer from '../img/buzzer.png';
import Trophy from '../img/trophy.png';
import { AlexaContext } from '../utils/AlexaService';
import { useContext } from 'react';
import generalFunctions from '../utils/generalFunctions';

function kebabifyString(str) {
  return str.split("").join("-");
}

const QuizList = () => {
    const { gameData, message, setMessage} = useContext(AlexaContext);

    const quizzes = gameData?.categoryData || [];

    function selectSubQuiz(id, quizName) {
      generalFunctions.animateButton(id);
      window.sendMessage(
        {
          "BTB_SUB_CAT_CHOICE": quizName
        }
      );
    }


    return (
      <div className='splash'>
        
      <div className="title-container">
          <h1 className="quiz-title">{gameData.categoryChoice?.display}</h1>
        </div>
        <div className="scrolling-list-container">
          <div className="scrolling-list">
            <ul>
              {quizzes.map((item, index) => (
                <li id={'subitem'+item.quizId} key={index} onClick={() =>selectSubQuiz('subitem' + index, item.voice)}>
                  <div className="quiz-name">{item.subCat}</div>
                  <div className="circles">
                    {[...Array(5).keys()].map(circleIndex => (
                      <span key={circleIndex} className={circleIndex < item.completedCount ? "circle green" : "circle"}>
                        {circleIndex === item.completedCount && item.completedCount !== 0 && item.completedCount < 5 ? <img src={Buzzer} alt="Buzzer" className="circle-image" /> : null}
                        {item.completedCount === 5 && circleIndex === 4 ? <img src={Trophy} alt="Trophy" className="circle-image" /> : null}
                      </span>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  

export default QuizList;

