import React, { useContext, useState, useEffect } from 'react';
import '../css/Splash.css'; // Adjust the path based on your file structure

import { AlexaContext } from '../utils/AlexaService';
import generalFunctions from '../utils/generalFunctions';

const GameOver = () => {
  const { gameData, setMessage } = useContext(AlexaContext);

  const currentLocale = gameData.locale || "en-US"; // Default to "en-US" if locale is not provided
  const [assets, setAssets] = useState({ logo: null, playAgain: null, switchGame: null, leaderboard: null });

  useEffect(() => {
    const loadAssets = async () => {
      let logo, playAgain, switchGame, leaderboard;

      try {
        switch (currentLocale) {
          case 'es-ES':
            logo = (await import('../img/spanish/btb-just-logo.png')).default;
            playAgain = (await import('../img/spanish/play-again.png')).default;
            switchGame = (await import('../img/spanish/switch-game.png')).default;
            leaderboard = (await import('../img/spanish/leaderboard-button.png')).default;
            break;
          case 'fr-FR':
            logo = (await import('../img/french/btb-just-logo.png')).default;
            playAgain = (await import('../img/french/play-again.png')).default;
            switchGame = (await import('../img/french/switch-game.png')).default;
            leaderboard = (await import('../img/french/leaderboard-button.png')).default;
            break;
          case 'de-DE':
            logo = (await import('../img/german/btb-just-logo.png')).default;
            playAgain = (await import('../img/german/play-again.png')).default;
            switchGame = (await import('../img/german/switch-game.png')).default;
            leaderboard = (await import('../img/german/leaderboard-button.png')).default;
            break;
          default:
            // Fallback to English assets as default
            logo = (await import('../img/english/btb-just-logo.png')).default;
            playAgain = (await import('../img/english/play-again.png')).default;
            switchGame = (await import('../img/english/switch-game.png')).default;
            leaderboard = (await import('../img/english/leaderboard-button.png')).default;
            break;
        }
      } catch (error) {
        console.error("Error loading assets: ", error);
      }

      setAssets({ logo, playAgain, switchGame, leaderboard });
    };

    loadAssets();
  }, [currentLocale]);

  function playAgainHandler(event) {
    generalFunctions.animateButton(event.currentTarget.id);
    setMessage({
      message: "SHOW_SPLASH"
    });

    window.sendMessage({
      "BTB_INTRO": true
    });
  }

  function switchGameHandler(event) {
    generalFunctions.animateButton(event.currentTarget.id);
    window.sendMessage({
      switchGame: true
    });
  }

  function showLeaderboardHandler(event) {
    generalFunctions.animateButton(event.currentTarget.id);
    setMessage({
      message: "SHOW_LEADERBOARD"
    });
  }

  return (
    <div className="splash">
      {/* Display the logo once it's loaded */}
      {assets.logo && (
        <img src={assets.logo} alt="Logo" className="logo" />
      )}
      <div className="buttons-container">
        {/* Display the buttons once the assets are loaded */}
        {assets.playAgain && (
          <button
            id="game-over-play-id"
            className="button play-again-button"
            aria-label="Play Again"
            onClick={playAgainHandler}
            style={{ backgroundImage: `url(${assets.playAgain})` }}
          />
        )}
        {assets.switchGame && (
          <button
            id="game-over-switch-id"
            className="button switch-game-button"
            aria-label="Switch Game"
            onClick={switchGameHandler}
            style={{ backgroundImage: `url(${assets.switchGame})` }}
          />
        )}
        {assets.leaderboard && (
          <button
            id="leaderboard-button-id"
            className={`button leaderboard-button-splash`}
            aria-label="Leaderboard"
            onClick={showLeaderboardHandler}
            style={{ backgroundImage: `url(${assets.leaderboard})` }}
          />
        )}
      </div>
    </div>
  );
};

export default GameOver;
