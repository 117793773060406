import { AlexaProvider } from './utils/AlexaService';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SplashTutorial from './views/SplashTutorial';
import './css/App.css';
import NavBar from './views/NavBar';

import Question from './views/Question';
function App() {
  return (
    <>
      <NavBar />
      <AlexaProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SplashTutorial />} />
          </Routes>
        </Router>
      </AlexaProvider>
    </>
  );
}

export default App;
