import React, { createContext, useEffect, useState, useRef } from 'react';

export const AlexaContext = createContext(null);

let state = "";

export const AlexaProvider = ({ children }) => {
  const [message, setMessage] = useState({});
  const [gameData, setGameData] = useState({});
  const sendMessage = useRef(() => {});
  const requestMicrophoneOpen = useRef(null);

  useEffect(() => {
    window.Alexa.create({ version: '1.1' })
      .then((args) => {
        const { alexa, message } = args;

        if (message.locale === "es-US") message.locale = "es-ES";
        if (message.locale === "es-MX") message.locale = "es-ES";
        if (message.locale === "fr-CA") message.locale = "fr-FR";
        if (message.locale === "en-US") message.locale = "en-US";
        if (message.locale === "en-GB") message.locale = "en-US";
        if (message.locale === "en-CA") message.locale = "en-US";
        
        let acceptedLocales = [
          "en-US", "de-DE", "fr-FR", "es-ES"
        ];

        if (!acceptedLocales.includes(message.locale) && message.locale) {
          message.locale = "en-US";
        }

        if (message.locale) {
          try {
            const imageElement = document.getElementById('mg_logo');
            
            let logoImport;
            switch (message.locale) {
              case "de-DE":
                logoImport = import('../img/navbar/logo-v3-de.png');
                break;
              case "es-ES":
                logoImport = import('../img/navbar/logo-v3-es.png');
                break;
              case "fr-FR":
                logoImport = import('../img/navbar/logo-v3-fr.png');
                break;
              default:
                logoImport = import('../img/navbar/logo-v3.png'); // Default to English
                break;
            }
        
            logoImport
              .then(module => {
                imageElement.src = module.default; // Set the logo dynamically
              })
              .catch(error => {
                console.error('Error loading logo:', error.message);
              });
            
          } catch (error) {
            console.error('Error updating image source:', error.message);
          }
        }
        
        

        setGameData((prevData) => ({
          ...prevData,
          trophyCount: message.trophyCount,
          catList: message.catList,
          isSubscriber: message.isSubscriber,
          topPlayers: message.topPlayers,
          currentPlayer: message.currentPlayer,
          locale: message.locale,
        }));

        if (message.deviceType === "FIRE_TV") {
          const logoElement = document.querySelector('.nav-section.logo-section img.logo');

          if (logoElement) {
            logoElement.style.marginTop = "2vh";
            logoElement.style.width = "55%";
          }

          const elements = document.querySelectorAll(
            '.nav-section.diamond .item.diamond, ' +
            '.nav-section.burger .item.burger, ' +
            '.nav-section.coins .team-rank-icon, ' +
            '.nav-section.team-rank .team-rank-icon'
          );

          if (elements.length > 0) {
            elements.forEach(element => {
              element.style.marginTop = '1.5vh';
            });
          }
        }

        if (message.coins) {
          const coinCountElement = document.getElementById('coin-count');
          if (coinCountElement) {
            coinCountElement.textContent = message.coins;
          }
        }

        if (message.isSubscriber) {
          const subDiamond = document.getElementById("subDiamond");
          if (subDiamond) {
            subDiamond.src = require("../img/navbar/sub-diamond-gif.gif");
          }
        }

        if (message.rankImagePath) {
          const rankImage = document.getElementById('teamIconId');
          if (rankImage) {
            rankImage.src = require(`../img/navbar/team-icons/${message.rankImagePath}`);
          }
        }

        alexa.speech.onStarted(() => {
          setMessage({
            "DISABLE_BUTTONS": true,
          });
        });

        alexa.speech.onStopped(() => {
          setMessage({
            "ENABLE_BUTTONS": true,
          });

          if (state === "DOING_FACT") {
            window.sendMessage({
              ASK_BTB_QUESTION: true,
            });
            state = "NOT_SET";
          }

          if (state === "FACT_GAME_OVER") {
            state = "NOT_SET";
            setMessage({
              message: "DO_GAME_OVER",
            });

            window.sendMessage({
              BTB_GAME_OVER: true,
            });
          }

          if (state === "FACT_GAME_WINNER") {
            state = "NOT_SET";
            setMessage({
              message: "DO_SHOW_TROPHY",
            });
          }
        });

        alexa.skill.onMessage((message) => {
          setMessage(message);

          if (message.message === "NEXT_QUESTION") {
            setGameData((prevData) => ({
              ...prevData,
              ...message.quizData,
            }));

            setMessage({
              message: "ASK_QUESTION",
            });
          }

          if (message.message === "DO_FACT") {
            state = "DOING_FACT";

            setGameData((prevData) => ({
              ...prevData,
              ...message.factData,
            }));
          }

          if (message.message === "DO_FACT_GAME_OVER") {
            state = "FACT_GAME_OVER";
          }

          if (message.message === "DO_FACT_GAME_WINNER") {
            state = "FACT_GAME_WINNER";

            setGameData((prevData) => ({
              ...prevData,
              ...message.factData,
            }));
          }
        });

        sendMessage.current = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        };

        requestMicrophoneOpen.current = (config) => {
          alexa.voice.requestMicrophoneOpen(config);
        };

        window.sendMessage = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        };
      })
      .catch(error => {
        console.log(`Alexa failed to initialise.`);
      });
  }, []);

  return (
    <AlexaContext.Provider value={{
      message,
      gameData,
      setGameData,
      sendMessage,
      setMessage,
      requestMicrophoneOpen: requestMicrophoneOpen.current,
    }}>
      {children}
    </AlexaContext.Provider>
  );
};
