import { useEffect, useContext, useState, useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import '../css/SplashScreen.css';
import Splash from './Splash';
import Question from './Question';
import FactView from './FactView';
import Trophy from './Trophy';
import GameOver from './GameOver';
import QuizList from './QuizList';
import { AlexaContext } from '../utils/AlexaService';
import Category from './Category';
import CenteredBox from './CenteredBox';
import CenterTrophy from './CenterTrophy';
import audioFunctions from '../utils/audioFunctions';
import CoinAnimation from './CoinAnimation';

import IntroAudio from '../audio/vo/btb-intro.mp3';
import IntroBacker from '../audio/game-fx/btb-backer.mp3';

import TfPrompt from '../audio/vo/tf-prompt.mp3';


import Leaderboard from './Leaderboard';

import BuzzPrompt from '../audio/vo/buzz-prompt.mp3';

import TrophyFx from '../audio/game-fx/big-win.mp3';


const SplashScreen = () => {
  const { message, gameData, setGameData, setMessage} = useContext(AlexaContext);
  const [showSplash, setShowSplash] = useState(true);
  const [showQuestionView , setShowQuestionView] = useState(false);
  const [showFactView, setShowFactView] = useState(false);
  const [showGameOverView, setShowGameOverView] = useState(false);
  const [showCategoryView, setShowCategoryView] = useState(false);
  const [showQuizListView, setShowQuizListView] = useState(false);
  const [showCenteredBox, setShowCenteredBox] = useState(false);
  const [showCenterTrophy, setShowCenterTrophy] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  const trophyRef = useRef(null);
  const coinRef = useRef(null);
  const [trophyCount, setTrophyCount] = useState(0);

  useEffect(() => {
    if (gameData.trophyCount) {
      setTrophyCount(gameData.trophyCount);
    }
  }, [gameData]) 

  const triggerTrophyAnim = () => {
    trophyRef.current.animateTrophy();
  };

  const setCoinAnim = (score) => {
    gameData.coins += score;
    coinRef.current.playAnimation(score)
  }

  useEffect(() => {

    if (message.DISABLE_BUTTONS) {
      setButtonsEnabled(false);
  }

    if (message.ENABLE_BUTTONS) {
        setButtonsEnabled(true);
    }

    if (message && message.message) {


      if (message.message === "SHOW_LEADERBOARD") {
        setShowSplash(false);
        setShowGameOverView(false);
        setShowLeaderboard(true);
      }

      // audioFunctions.playWithIncreasedVolume(
      //   TrophyFx, 2, () => {},
      // )
      if (message.message === "SHOW_SPLASH") {
        setShowSplash(true);
        setShowGameOverView(false);
        setShowLeaderboard(false);
      }
      // Lets do some logic

      if (message.message === "SET_ASK_QUESTION") {
        // Lets animate away the icons and stuff then pop up the first question
        // lets se this game data
        setGameData(
          {
            ...gameData,
            ...message.quizData
          }
        );
        
        setShowCenteredBox(false);
        setShowQuestionView(true);
        setShowSplash(false);
        setShowFactView(false);
        setShowQuizListView(false);
      }

      if (message.message === "INTRODUCE_QUIZ") {
        // Lets animate away the icons and stuff then pop up the first question lets se this game data
        // Start by introducing the categories, lets take the Quiz Id and then speak the VO
        setShowSplash(false)
        setShowCenteredBox(true);
        setShowCategoryView(false);
        setShowQuizListView(false);
        setGameData(
          {
            ...gameData,
            ...message.quizData
          }
        );

        setTimeout(() => {
          setShowCenteredBox(false);
          setShowQuestionView(true);
          setShowSplash(false);
          setShowFactView(false);
          // setMessage(
          //   {
          //     "ENABLE_BUTTONS": true
          //   }
          // )
        }, 2000)
      }

      if (message.message === "SHOW_CATEGORIES") {
        // Its show categories so lets show the category page!

        setGameData(
          {
            ...gameData,
            categories: message.categories
          }
        )
        setShowCategoryView(true);
        setShowSplash(false);
      }

      if (message.message === "SHOW_SUB_CAT_VIEW") {
                // Then we need to get all the quiz data for that category
            setGameData(
              {
                ...gameData,
                categoryChoice: message.catChoice,
                categoryData: message.categoryData
              }
            );

            setShowCategoryView(false);
            setShowQuizListView(true);
            setShowSplash(false);


      }

      // if (message.SCROLL_ID) {
      //   // Need to specify the ID now
      //   const element = document.getElementById(message.SCROLL_ID);
      //   if (element) {
      //     element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //   }
      // }


      if (message.message === "ASK_QUESTION") {
        // Lets ani mate away the icons and stuff then pop up the first question
        // lets se this game data
        setShowCenteredBox(false);
        setShowQuestionView(true);
        setShowSplash(false);
        setShowFactView(false);
      }


      if (message.message === "DO_FACT" || message.message === "DO_FACT_GAME_OVER" || message.message === "DO_FACT_GAME_WINNER") {
        setShowFactView(true);
        setShowQuestionView(false);
        setShowSplash(false)
      }

      if (message.message === "DO_GAME_OVER") {
        setShowGameOverView(true);
        setShowFactView(false);
        setShowQuestionView(false);
      }

    

      if (message.message === "DO_SHOW_TROPHY") {
        // Show the trophy view, then hide everything
        setShowFactView(false);
        setShowQuestionView(false);
        setShowCenterTrophy(true);

        // Do trophy anim here
        // Pulse this trophy and stuff
        setCoinAnim(3);

        // We can make a setmessage fnction to do this
        setTrophyCount(trophyCount + 1);
        triggerTrophyAnim();

        // lets play some audio here for the trophy anim
        audioFunctions.playWithIncreasedVolume(
            TrophyFx, 2, () => {
              setShowGameOverView(true);
              setShowFactView(false);
              setShowQuestionView(false);
              setShowCenterTrophy(false);
              window.sendMessage(
                { 
                  
                  BTB_WIN_TROPHY: true 
                
                }
              )
            },
        )

      }

    }
  }, [message]);

  // useEffect( () => {
  //   audioFunctions.playWithIncreasedVolume(IntroBacker, 2);
  //   setTimeout(() => {
  //     audioFunctions.playWithIncreasedVolume(IntroAudio, 3, () => {
  //       // Shake the BTB logo and play the buzz FX
  //       audioFunctions.playWithIncreasedVolume(BuzzPrompt, 3, () => {
  //           window.sendMessage(
  //             {
  //               BTB_INTRO_MIC: true
  //             }
  //           ) 
  //       }, true)
  //     });
  //   }, 2000)
  // }, []);

  return (
    <div style={{height:'100%', width:'100%'}}>
    <div className='splash-screen'>
        {showLeaderboard && <Leaderboard></Leaderboard>}
        {showSplash && <Splash buttonsEnabled={buttonsEnabled}></Splash> }
        {showQuestionView && <Question buttonsEnabled={buttonsEnabled}></Question>}
        {showFactView && <FactView></FactView> }
        {showGameOverView && <GameOver></GameOver> }
        {showCategoryView && <Category></Category> }
        {showQuizListView && <QuizList></QuizList> }
        {showCenteredBox && <CenteredBox></CenteredBox>}

        <Trophy trophyCount={trophyCount}></Trophy> 
        <CenterTrophy ref={trophyRef}></CenterTrophy> 
  
      </div>
    <CoinAnimation ref={coinRef} />
    </div>

  )
};

export default SplashScreen;
