import React, { useContext, useEffect, useState } from 'react';
import '../css/CenteredBox.css'; // Assuming you'll store the CSS here
import { AlexaContext } from '../utils/AlexaService';

import Geography from '../img/categories/geography.png';
import History from '../img/categories/history.png';
import Movie from '../img/categories/movie.png';
import Politics from '../img/categories/politics.png';
import PopCulture from '../img/categories/pop-culture.png';
import Sport from '../img/categories/sport.png';
import Daily from '../img/categories/clock.png';

const CATEGORY_IMAGES = {
    GEO: Geography,
    HIS: History,
    MOV: Movie,
    POL: Politics,
    POP: PopCulture,
    DAI: Daily,
    SPO: Sport
};

const CenteredBox = () => {
  const { gameData } = useContext(AlexaContext);
  const quizData = gameData.quizData || {};

  // Using useState to dynamically add the animation class on component mount
  const [animateClass, setAnimateClass] = useState('');

  let locCatMap = {
    "de-DE": "Täglich",
    "fr-FR": "Quotidien",
    "es-ES": "Diario",
    "en-US": "Daily"
  };
  
  // Fallback to "Daily" if the locale doesn't match any of the keys
  let locCat = locCatMap[gameData.locale] || "Daily";
  

  useEffect(() => {
    // Apply the animation class right after the component mounts
    setAnimateClass('animate');

    // Optionally, you can remove the class after the animation duration if needed
    // But since this is only on mount, it might not be necessary unless you plan to
    // trigger the animation again in the same component lifecycle
  }, []); // Empty dependency array to ensure this runs only once on mount
// ${animateClass}
  return (
    <div className={`centered-container category`}>
      <img src={CATEGORY_IMAGES[quizData.code] || Daily} alt={quizData.categoryName || locCat} className="centered-image"/>
      <div className="category-name">{quizData.categoryName || locCat}</div>
    </div>
  );
};

export default CenteredBox;
