import React, { useContext, useEffect, useState } from 'react';
import '../css/Trophy.css'; // Import your CSS file
import trophyImage from '../img/trophy.png'; // Import trophy image
import { AlexaContext } from '../utils/AlexaService';
const Trophy = ({trophyCount=0}) => {
    const {gameData} = useContext(AlexaContext);


  return (
    <div className="trophy-container">

      <span className="trophy-count">{trophyCount}x</span>
      <img src={trophyImage} className="trophy-image" alt="Trophy" />
    </div>
  );
};

export default Trophy;
