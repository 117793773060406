import React, { useContext, useEffect, useRef, useState } from 'react';
import SliderImg from '../img/slider.png';

import Geography from '../img/categories/geography.png';
import History from '../img/categories/history.png';
import Movie from '../img/categories/movie.png';
import Politics from '../img/categories/politics.png';
import PopCulture from '../img/categories/pop-culture.png';
import Sport from '../img/categories/sport.png';
import Clock from '../img/categories/clock.png';
import '../css/Menu.css';

import { AlexaContext } from '../utils/AlexaService';

import generalFunctions from '../utils/generalFunctions';

const CAT_MAP = {
  "Geography": {
    image: Geography,
    raw: "geography",
    name: "Geography"
  },
  "History": {
    image: History,
    raw: "history",
    name: "History"
  },
  "Movies & TV": {
    image: Movie,
    raw: "movies and TV",
    name: "Movies & TV"
  },
  "Politics": {
    image: Politics,
    raw: "politics",
    name: "Politics"
  },
  "Pop Culture": {
    image: PopCulture,
    raw: "pop culture",
    name: "Pop Culture"
  },
  "Sport": {
    image: Sport,
    raw: "sports",
    name: "Sports"
  },
  "General Knowledge": {
    image: Clock,
    raw: "daily",
    name: "Daily"
  }
}

const CAT_MAP_ES = {
  "Geografía": {
    image: Geography,
    raw: "geografía",
    name: "Geografía",
    display: "Geografía"
  },
  "Historia": {
    image: History,
    raw: "historia",
    name: "Historia",
    display: "Historia"
  },
  "Películas y TV": {
    image: Movie,
    raw: "películas y TV",
    name: "Películas y TV",
    display: "Películas & TV"
  },
  "Política": {
    image: Politics,
    raw: "política",
    name: "Política",
    display: "Política"
  },
  "Cultura Pop": {
    image: PopCulture,
    raw: "cultura pop",
    name: "Cultura Pop",
    display: "Cultura Pop"
  },
  "Deportes": {
    image: Sport,
    raw: "deportes",
    name: "Deportes",
    display: "Deportes"
  },
  "Diario": {
    image: Clock,
    raw: "diario",
    name: "Diario",
    display: "Diario"
  }
};

const CAT_MAP_FR = {
  "Géographie": {
    image: Geography,
    raw: "géographie",
    name: "Géographie",
    display: "Géographie"
  },
  "Histoire": {
    image: History,
    raw: "histoire",
    name: "Histoire",
    display: "Histoire"
  },
  "Films & TV": {
    image: Movie,
    raw: "films et TV",
    name: "Films et TV",
    display: "Films & TV"
  },
  "Politique": {
    image: Politics,
    raw: "politique",
    name: "Politique",
    display: "Politique"
  },
  "Culture Pop": {
    image: PopCulture,
    raw: "culture pop",
    name: "Culture Pop",
    display: "Culture Pop"
  },
  "Sport": {
    image: Sport,
    raw: "sport",
    name: "Sport",
    display: "Sport"
  },
  "Quotidien": {
    image: Clock,
    raw: "quotidien",
    name: "Quotidien",
    display: "Quotidien"
  }
};

const CAT_MAP_DE = {
  "Geografie": {
    image: Geography,
    raw: "geografie",
    name: "Geografie"
  },
  "Geschichte": {
    image: History,
    raw: "geschichte",
    name: "Geschichte"
  },
  "Filme & TV": {
    image: Movie,
    raw: "filme und tv",
    name: "Filme & TV"
  },
  "Politik": {
    image: Politics,
    raw: "politik",
    name: "Politik"
  },
  "Popkultur": {
    image: PopCulture,
    raw: "popkultur",
    name: "Popkultur"
  },
  "Sport": {
    image: Sport,
    raw: "sport",
    name: "Sports"
  },
  "Täglich": {
    image: Clock,
    raw: "täglich",
    name: "Täglich"
  }
}

function getChooseCategoryString(languageCode) {
  const translations = {
    "fr-FR": "CHOISISSEZ UNE CATÉGORIE",
    "es-ES": "ELIGE UNA CATEGORÍA",
    "es-US": "ELIGE UNA CATEGORÍA",
    "de-DE": "WÄHLE EINE KATEGORIE"
  };

  return translations[languageCode] || "CHOOSE A CATEGORY";
}


const MenuItems = () => {
  const { message, setMessage, gameData } = useContext(AlexaContext);
  const menuRef = useRef();
  const [scrollToEnd, setScrollToEnd] = useState(true);

  let categories = gameData.categories || [];

  const currentLocale = gameData.locale || "en-US";

  let locCatMap = CAT_MAP;

  switch (currentLocale) {
    case "de-DE":
      locCatMap = CAT_MAP_DE;
      break;
    case "fr-FR":
      locCatMap = CAT_MAP_FR;
      break;
    case "es-ES":
      locCatMap = CAT_MAP_ES;
      break;
    default:
      locCatMap = CAT_MAP;  // Default to English
      break;
  }






  categories = categories.map((x) => {
    if (locCatMap[x]) {
      return {
        image: locCatMap[x].image,
        name: locCatMap[x].name,
        raw: locCatMap[x].raw
      }
    }
  });

  categories = categories.filter(Boolean);


  useEffect(() => {
    // Toggle scroll direction on each trigger
    if (message.message === "SCROLL_LIST") {
      if (scrollToEnd) {
        scrollTo('end');
      } else {
        scrollTo('start');
      }
      setScrollToEnd(!scrollToEnd); // Flip the scroll direction for next time
    }

  }, [message]);

  const scrollTo = (direction) => {
    const scrollWidth = menuRef.current.scrollWidth;
    const width = menuRef.current.clientWidth;
    const maxScrollLeft = scrollWidth - width;

    if (direction === 'end') {
      menuRef.current.scrollLeft = maxScrollLeft > 0 ? maxScrollLeft : 0;
    } else if (direction === 'start') {
      menuRef.current.scrollLeft = 0;
    }
  };


  function setActiveQuiz(id, category) {
    generalFunctions.animateButton(id)
    window.sendMessage(
      {
        "BTB_SUB_CAT": category,
      }
    );
  }

  return (
    <div className='splash'>
      <div id='cat-title'>{getChooseCategoryString(gameData.locale)}</div>
      <div className='menu-items-container' ref={menuRef}>
        {categories.map((item, index) => (
          <div key={index} id={'item' + index} className='menu-item' onClick={() => setActiveQuiz('item' + index, item.raw)}>
            <div className='menu-item-inner' style={{ backgroundImage: `url(${item.image})`, backgroundColor: 'rgba(0, 0, 0, 0.3)', borderRadius: "10px" }}>
              <div className='menu-item-label'>{item.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuItems;
